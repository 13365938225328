import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import React from "react";

const AdminSettings = () => {
  return <div className="container">
    <Breadcrumb className="breadcrumb_wrp" style={{marginTop: 20}}>
      <Breadcrumb.Item>
        <Link to="/admin">Админ</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Настройки
      </Breadcrumb.Item>
    </Breadcrumb>
  </div>
}

export default AdminSettings;