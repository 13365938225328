import React, {useCallback, useEffect, useState} from "react";
import styles from "../CourseCard/CourseCard.module.scss";
import {Spin} from 'antd';
import {Breadcrumb} from 'antd';
import {Link, useParams} from "react-router-dom";
import {apiQuery, apiQueryError} from "../../Utils";

const CourseCard = () => {

  const {courseId} = useParams();
  const [data, setData] = useState();
  const [load, setLoad] = useState(false);

  const courseGet = useCallback(() => {
    if (!courseId) return;
    setLoad(true)
    apiQuery("/private/courses/" + courseId)
      .then((data) => {
        setData(data);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        apiQueryError({error})
      })
  }, [courseId])

  useEffect(() => {
    courseGet()
  }, [courseGet, courseId]);

  if (load || !data) return <Spin/>;

  return (
    <div className={styles.card_course}>
      <div className="container">
        <Breadcrumb className="breadcrumb_wrp">
          <Breadcrumb.Item><a href="/">Главная</a></Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/cabinet">Кабинет</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {data.name}
          </Breadcrumb.Item>
        </Breadcrumb>

        <h4>{data.name}</h4>

        <div className={styles.less_wrp}>
          {data.lessonsIdData.map((item, index) => <Link to={`/cabinet/course/${courseId}/${item._id}`}>
            <span>{index+1}</span>
            <div><b>{item.name || ''}</b>
              <p>{item.description}</p>
            </div>
          </Link>)}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
