import React from "react";
import styles from "../Footer/Footer.module.scss";
import { Col, Row } from 'antd';
import logo from "../../assets/images/telegram.svg";
import { Link } from "react-router-dom";
import {
  InstagramOutlined,
} from '@ant-design/icons';
import logo1 from "../../assets/images/logo2.svg";
import kz from "../../assets/images/kz.png";
// import ru from "../../assets/images/ru.png";
// import fb from "../../assets/images/facebook-alt-svgrepo-com.svg";

const Footer = () => { 
   return (
    <div className={styles.footer}>
    
          <div className="container">
              <Row>
                <Col sm={24} md={8} xs={24} className={styles.foot}>
                <Link className={styles.logo} to="/"><img src={logo1} alt="logo"/></Link>
                 {/*<span>Студия вкусных десертов</span>
               <Link to="">Политика конфиденциальности</Link>*/}
                </Col>
                <Col sm={24} md={8} xs={24} className={styles.foot1}>
                <p>Реквизиты <img src={kz} alt="logo"/></p>
                <span>4400 4301 5528 2145</span>
                <span>MADINA EVLOEVA</span></Col> 
                {/*<Col sm={24} xs={24} md={6}>
                <p>Реквизиты <img src={ru} alt="logo"/></p>
                <span>2202 2008 2509 8989</span>
                <span>MADINA EVLOEVA</span>
                </Col>*/}
                <Col sm={24} xs={24} md={6} className={styles.footer_right}>
                <div className={styles.footer_social}>
                <a href="https://www.instagram.com/ta.patissiere/" target="_blank" rel="noreferrer"><InstagramOutlined /></a>
                <a href="https://t.me/+77079333095"><img src={logo} alt={''}/></a>
                {/*<Link href=""><img src={fb}/></Link>*/}
              
                </div>   
                </Col> 
              </Row>
               <div className={styles.footer_bot}>
               <p>© copyright 2023 by Ta Pâtisserie</p>
               </div>
          </div>
    </div>
    
  );
};

export default Footer;
