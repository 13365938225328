import React, {useState} from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import {Link} from "react-router-dom";
import AppStore from "../../src/components/App/AppStore";
import styles from "../pages/Home.module.scss";
import {Carousel, Row, Col, Button, Form, Input, Modal, Collapse, message} from 'antd';
import about from "../assets/images/about1.png";
import img3 from "../assets/images/img30.jpg";
import img2 from "../assets/images/181_n.jpg";
import img1 from "../assets/images/img31.jpg";
import img4 from "../assets/images/IMG_0476.JPG";
import img5 from "../assets/images/image--1.jpg";
import vid1 from "../assets/images/video-1-1.jpg";
import vid2 from "../assets/images/banner-bg-shape-1-1.png";
import otz1 from "../assets/images/img1.jpeg";
import otz2 from "../assets/images/img2.jpeg";
import otz3 from "../assets/images/img3.jpeg";
import otz5 from "../assets/images/img5.jpeg";
import otz6 from "../assets/images/img6.jpeg";
import otz7 from "../assets/images/img7.jpeg";
import otz8 from "../assets/images/img8.jpeg";
import otz9 from "../assets/images/img9.jpeg";
import otz10 from "../assets/images/img10.jpeg";
import otz11 from "../assets/images/img11.jpeg";
import otz12 from "../assets/images/img12.jpeg";
import otz13 from "../assets/images/img13.jpeg";
import otz14 from "../assets/images/img14.jpeg";
import otz15 from "../assets/images/img15.jpeg";
import otz16 from "../assets/images/img16.jpeg";
import otz17 from "../assets/images/img17.jpeg";
import otz18 from "../assets/images/img18.jpeg";
import otz19 from "../assets/images/img19.jpeg";
import otz20 from "../assets/images/img20.jpeg";

import pr1 from "../assets/images/pr1.jpeg";
import pr2 from "../assets/images/pr2.jpeg";
import pr3 from "../assets/images/pr3.jpeg";
import pr4 from "../assets/images/pr4.jpeg";
import pr5 from "../assets/images/pr5.jpeg";
import pr6 from "../assets/images/pr6.jpeg";
import pr7 from "../assets/images/pr7.jpeg";
import pr8 from "../assets/images/pr8.jpeg";
import pr9 from "../assets/images/pr9.jpeg";
import pr11 from "../assets/images/pr11.jpeg";
import pr10 from "../assets/images/pr10.jpeg";

import { observer } from "mobx-react-lite";

import WOW from 'wowjs';
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined, PlayCircleOutlined, CheckCircleOutlined, RightOutlined, LeftOutlined
} from '@ant-design/icons';
import 'animate.css';
import {apiQuery, apiQueryError} from "../Utils";

const {Panel} = Collapse;
const setting = {
  dots: true,
  speed: 500,
  slidesToShow: 4,
  /*swipeToSlide: true,*/
  arrows: true,
  nextArrow: <RightOutlined/>,
  prevArrow: <LeftOutlined/>,
  infinite: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const {TextArea} = Input;

function ModalVideo({open, setOpen}) {
  if (!open) return null;
  return <Modal
    centered
    open={open}
    onOk={() => setOpen(false)}
    footer={null}
    onCancel={() => setOpen(false)}
  >
    <video className={styles.video_bl} poster="" src="https://ta-patissiere.com/api/file/63f24d4ab94a5d093b33cbd3"
           preload="auto" controls="controls" autoplay="" loop="" playsinline="" muted=""/>
  </Modal>
}

function Home() {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoad(true);
    apiQuery("/emailSend", {
      method: 'POST',
      body: values
    })
      .then((data) => {
        setLoad(false);
        message.success('Сообщение отправлено');
        form.resetFields();
      })
      .catch((error) => {
        setLoad(false);
        apiQueryError({error})
      })
  }

  new WOW.WOW({
    live: false
  }).init();

  return (
    <div className={styles.home_page}>
      <ModalVideo open={open} setOpen={setOpen}/>
      <div className={styles.slider_bl}>
        <Header/>
        <section className={styles.banner_one} id="main">
          <img src={vid2} alt="" className={styles.banner_one__moc}/>
          <div className="container">
            <div className={styles.banner_one__video + " wow animate__fadeInUp animate__animated"}
                 data-wow-animation-duration="1500ms">
              <img src={vid1} alt=""/>
              <Link to="#" onClick={() => setOpen(true)}
                    className={styles.banner_one__video_btn + " video-popup"}><PlayCircleOutlined/></Link>
            </div>
            <Row>
              <Col span={12}>
                <div className={styles.banner_one__content}>
                  <h3>Авторский курс <span> от Мадины Евлоевой</span></h3>
                  <p className={styles.titl_bb}>«Эстетика современных десертов»</p>
                  <p>Муссовые десерты - это один из самых популярных трендов современного кондитерского мира.<br/>В этом
                    курсе учимся создавать современные, элегантные, стильные десерты с «TA PATISSIERE»</p>
                  <a className={styles.banner_one__btn + " thm-btn"} href="#buy">
                    Купить
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </section>


      </div>
      <div className={styles.about_author} id="about_author">
        <div className="container">
          <div className={styles.title_bl}>
            <h4>Об авторе</h4>
            <span className={styles.landing_title}/>
          </div>
          <Row>
            <Col span={12} className="animate__animated wow animate__fadeInLeft" data-wow-animation-duration="1800ms">
              <div className={styles.around_k}>
                <div className={styles.around_bg + " animate__animated wow animate__fadeIn animate__delay-2s"}
                     data-wow-animation-duration="2000ms"/>
                <img src={about} alt={''}/>
              </div>
            </Col>
            <Col span={12}>

              <div className={styles.about_text}>
                <p>Мадина Евлоева</p>
                <span><CheckCircleOutlined/> Основатель сладкого бренда «TA PATISSIERE»</span>
                <span><CheckCircleOutlined/> Более 4 лет кондитерского опыта: сотни приготовленных десертов, работа с муссами и шоколадом.</span>
                <span><CheckCircleOutlined/> Провела более 30 групповых и индивидуальных мастер классов</span>
                <span><CheckCircleOutlined/> За 1 год выпустила более 300 учениц</span>
                <span><CheckCircleOutlined/> Личная студия «TA PATISSIERE» </span>

              </div>
            </Col>
          </Row>
        </div>
      </div>


      <div className={styles.my_coursce} id="kurs">
        <div className={styles.title_bl}>
          <h4>Авторский курс<br/>«Эстетика современных десертов»</h4>
          <span className={styles.landing_title}/>
        </div>
        <div className="container">
          {AppStore.width <= 768 ? (
            <><img src={img5} alt=""/>
              <div className={styles.my_coursce_d}>
                <Collapse defaultActiveKey={['1']}>
                  <Panel header={<p>ДЛЯ КОГО ПОДОЙДЕТ КУРС:</p>} key="1">
                    <ul>
                      <li><CheckCircleOutlined/> Для тех, кто мечтает стать кондитером, у кого совсем нет опыта.</li>
                      <li><CheckCircleOutlined/> Для тех, кто готовит для себя и близких</li>
                      <li><CheckCircleOutlined/> Для мамочек в декрете</li>
                      <li><CheckCircleOutlined/> Для кондитеров, которые хотят усовершенствовать свои знания и опыт</li>
                    </ul>
                  </Panel>
                  <Panel header={<p>ЧТО ВХОДИТ В КУРС:</p>} key="2">
                    <ul>
                      <li><CheckCircleOutlined/> 8 рецептов самых популярных десертов</li>
                      <li><CheckCircleOutlined/> Подробные видео уроки к каждому рецепту</li>
                      <li><CheckCircleOutlined/> Подробный видеообзор про ингредиенты и инвентарь</li>
                      <li><CheckCircleOutlined/> Секреты хранения десертов.</li>
                      <li><CheckCircleOutlined/> Памятка по работе с желирующими агентами.</li>
                      <li><CheckCircleOutlined/> Проверка домашнего задания.</li>
                      <li><CheckCircleOutlined/> Обратная связь от автора в течении 1 месяца.</li>
                      <li><CheckCircleOutlined/> Доступ к урокам на 1 год в личном кабинете</li>
                      <li><CheckCircleOutlined/> Именной сертификат о прохождении курса</li>

                    </ul>
                  </Panel>
                  <Panel header={<p>ЧЕМУ ВЫ НАУЧИТЕСЬ:</p>} key="3">
                    <ul>
                      <li><CheckCircleOutlined/> Основам работы с муссовыми десертами и CHOCCO eggs</li>
                      <li><CheckCircleOutlined/> Научитесь работать с шоколадом. Темперирование шоколада несколькими
                        способами в домашних условиях. Создавать шоколадный декор.
                      </li>
                      <li><CheckCircleOutlined/> Научитесь готовить несколько видов глазури: (зеркальная, нейтральная,
                        шоколадный велюр (работа с краскопультом)).
                      </li>
                      <li><CheckCircleOutlined/> Хранению продуктов и готовых изделий. Узнаете где и как правильно
                        хранить продукты и готовые изделия, чтобы они не потеряли свой вид и вкус.
                      </li>
                      <li><CheckCircleOutlined/> Готовить более 30 составляющих десерта - Ягодные прослойки, Сливочная
                        карамель, Кремовая Карамель, Шоколадный брауни, Муссы, Ганаши, Намекала, конфи, кремю, компоте,
                        хрустилан, миндальный бисквит, бисквит Муале, Фундучный бисквит, ванильный бисквит).
                      </li>
                      <li><CheckCircleOutlined/> В дальнейшем сможете комбинировать и придумывать свои вкусовые
                        сочетания.
                      </li>
                    </ul>
                  </Panel>
                  <Panel header={<p>БОНУС от меня</p>} key="4">
                    <ul>
                      <li><CheckCircleOutlined/> Урок шоколадная тарталетка (Миндальное сабле в сочетании с шоколадным
                        муссом, миндальная Карамель, шоколадное покрытие)
                      </li>
                    </ul>
                  </Panel>
                </Collapse>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col span={8} className={styles.my_coursce_dl}>
                  <div className={styles.my_coursce_d}>
                    <p>ДЛЯ КОГО ПОДОЙДЕТ КУРС:</p>
                    <ul>
                      <li><CheckCircleOutlined/> Для тех, кто мечтает стать кондитером, у кого совсем нет опыта.</li>
                      <li><CheckCircleOutlined/> Для тех, кто готовит для себя и близких</li>
                      <li><CheckCircleOutlined/> Для мамочек в декрете</li>
                      <li><CheckCircleOutlined/> Для кондитеров, которые хотят усовершенствовать свои знания и опыт</li>
                    </ul>

                  </div>
                  <div className={styles.my_coursce_d}>
                    <p>ЧТО ВХОДИТ В КУРС:</p>
                    <ul>
                      <li><CheckCircleOutlined/> 8 рецептов самых популярных десертов</li>
                      <li><CheckCircleOutlined/> Подробные видео уроки к каждому рецепту</li>
                      <li><CheckCircleOutlined/> Подробный видеообзор про ингредиенты и инвентарь</li>
                      <li><CheckCircleOutlined/> Секреты хранения десертов.</li>
                      <li><CheckCircleOutlined/> Памятка по работе с желирующими агентами.</li>
                      <li><CheckCircleOutlined/> Проверка домашнего задания.</li>
                      <li><CheckCircleOutlined/> Обратная связь от автора в течении 1 месяца.</li>
                      <li><CheckCircleOutlined/> Доступ к урокам на 1 год в личном кабинете</li>
                      <li><CheckCircleOutlined/> Именной сертификат о прохождении курса</li>

                    </ul>
                  </div>

                </Col>
                <Col span={8} className={styles.my_coursce_dfoto}><img src={img5} alt=""/></Col>
                <Col span={8} className={styles.my_coursce_dr}>
                  <div className={styles.my_coursce_d}>
                    <p>ЧЕМУ ВЫ НАУЧИТЕСЬ:</p>
                    <ul>
                      <li><CheckCircleOutlined/> Основам работы с муссовыми десертами и CHOCCO eggs</li>
                      <li><CheckCircleOutlined/> Научитесь работать с шоколадом. Темперирование шоколада несколькими
                        способами в домашних условиях. Создавать шоколадный декор.
                      </li>
                      <li><CheckCircleOutlined/> Научитесь готовить несколько видов глазури: (зеркальная, нейтральная,
                        шоколадный велюр (работа с краскопультом)).
                      </li>
                      <li><CheckCircleOutlined/> Хранению продуктов и готовых изделий. Узнаете где и как правильно
                        хранить продукты и готовые изделия, чтобы они не потеряли свой вид и вкус.
                      </li>
                      <li><CheckCircleOutlined/> Готовить более 30 составляющих десерта - Ягодные прослойки, Сливочная
                        карамель, Кремовая Карамель, Шоколадный брауни, Муссы, Ганаши, Намекала, конфи, кремю, компоте,
                        хрустилан, миндальный бисквит, бисквит Муале, Фундучный бисквит, ванильный бисквит).
                      </li>
                      <li><CheckCircleOutlined/> В дальнейшем сможете комбинировать и придумывать свои вкусовые
                        сочетания.
                      </li>
                    </ul>
                  </div>

                </Col>
              </Row>
              <div className={styles.my_coursce_d}>
                <p>БОНУС от меня</p>
                <ul>
                  <li><CheckCircleOutlined/> Урок шоколадная тарталетка (Миндальное сабле в сочетании с шоколадным
                    муссом, миндальная Карамель, шоколадное покрытие)
                  </li>
                </ul>
              </div>
            </>
          )}


        </div>
      </div>

      <div className={styles.my_serv} id="programm">
        <div className="container">
          <div className={styles.title_bl}>
            <h4>Программа курса</h4>
            <span className={styles.landing_title}></span>
          </div>
          <div className={styles.my_serv_wp}>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr1 + ")"}}/>
              <p>Торт «Запах клубники»</p>

            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr2 + ")"}}/>
              <p>Торт «Персик-гибискус-манго»</p>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr3 + ")"}}/>
              <p>Торт «Вишня Чизкейк»</p>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr4 + ")"}}/>
              <p>Торт «Экзотик»</p>
            </div>


            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr5 + ")"}}/>
              <p>Торт «Малиновый поцелуй»</p>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr6 + ")"}}/>
              <p>«Choco eggs» фундучный</p>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr7 + ")"}}/>
              <p>«Choco eggs» лимонный шейк</p>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr8 + ")"}}/>
              <p>«Choco eggs» арахис-карамель</p>
            </div>


            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr9 + ")"}}/>
              <p>Бонус. Миндальная тарталетка</p>
            </div>
            <div className={styles.my_serv_item}>
            <div className={styles.img_bl} style={{backgroundImage: "url(" + pr11 + ")"}}/>
              <p>Бонус. Глазурь Зеркальная</p>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + pr10 + ")"}}/>
              <p>Бонус. Глазурь Нейтральная</p>
            </div>
            <div className={styles.pricee}>
            <p>Стоимость данного курса <b>59 900 тенге</b> <i>49 900 тенге</i></p>
            <span>Только 2 дня цена по акции с обратной связью от меня в течении месяца, после будет повышение цены.</span>
          </div>
          </div>
        </div>
      </div>


      <div className={styles.about_author} id="rew">
        <div className="container">
          <div className={styles.title_bl}>
            <h4>Отзывы</h4>
            <span className={styles.landing_title}/>
          </div>
          <Carousel {...setting}>
            <div>
              <img src={otz1} alt={''}/>
            </div>
            <div>
              <img src={otz2} alt={''}/>
            </div>
            <div>
              <img src={otz3} alt={''}/>
            </div>
            <div>
              <img src={otz5} alt={''}/>
            </div>
            <div>
              <img src={otz6} alt={''}/>
            </div>
            <div>
              <img src={otz7} alt={''}/>
            </div>
            <div>
              <img src={otz8} alt={''}/>
            </div>
            <div>
              <img src={otz9} alt={''}/>
            </div>
            <div>
              <img src={otz10} alt={''}/>
            </div>
            <div>
              <img src={otz11} alt={''}/>
            </div>
            <div>
              <img src={otz12} alt={''}/>
            </div>
            <div>
              <img src={otz13} alt={''}/>
            </div>
            <div>
              <img src={otz14} alt={''}/>
            </div>
            <div>
              <img src={otz15} alt={''}/>
            </div>
            <div>
              <img src={otz16} alt={''}/>
            </div>
            <div>
              <img src={otz17} alt={''}/>
            </div>
            <div>
              <img src={otz18} alt={''}/>
            </div>
            <div>
              <img src={otz19} alt={''}/>
            </div>
            <div>
              <img src={otz20} alt={''}/>
            </div>
          </Carousel>
        </div>
      </div>
      <div className={styles.my_serv} id="services">
        <div className="container">
          <div className={styles.title_bl}>
            <h4>Мои услуги</h4>
            <span className={styles.landing_title}/>
          </div>
          <div className={styles.my_serv_wp}>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + img2 + ")"}}/>
              <p>Торты</p>
              <div className={styles.my_serv_flex}>
                <a href="https://wa.me/+77079333095">Заказать</a></div>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + img1 + ")"}}/>
              <p>Десерты</p>
              <div className={styles.my_serv_flex}>
                <a href="https://wa.me/+77079333095">Заказать</a></div>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + img3 + ")"}}/>
              <p>Десерты</p>
              <div className={styles.my_serv_flex}>
                <a href="https://wa.me/+77079333095">Заказать</a></div>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + img4 + ")"}}/>
              <p>Торты</p>
              <div className={styles.my_serv_flex}>
                <a href="https://wa.me/+77079333095">Заказать</a></div>
            </div>


            {/*<div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + img3 + ")"}}/>
              <p>Шоколад</p>
              <div className={styles.my_serv_flex}><span>от 2500 тг.</span>
                <Link to="https://wa.me/+77079333095">Заказать</Link></div>
            </div>
            <div className={styles.my_serv_item}>
              <div className={styles.img_bl} style={{backgroundImage: "url(" + img4 + ")"}}/>
              <p>Рулеты</p>
              <div className={styles.my_serv_flex}><span>от 2500 тг.</span><Link to="https://wa.me/+77079333095">Заказать</Link></div>
            </div>*/}
          </div>
        </div>
      </div>
      <div className={styles.my_coursce} id="buy">
        <div className="container">
        <Row>
          <Col span={10} md={10} sm={24} xs={24}>
          <video className={styles.video_bl2} poster="" src="https://ta-patissiere.com/api/file/63f24d4ab94a5d093b33cbd3"
           preload="auto" controls="controls" loop="" playsinline="" muted=""/>
          </Col>
          <Col span={14} md={14} sm={24} xs={24}>
          <div className={styles.title_bl}  >
          <h4 style={{textAlign: 'left'}}>Оплата</h4>
          <span style={{margin: '0'}} className={styles.landing_title}/>
        </div>
          <div className={styles.my_coursce_d + " " + styles.my_coursce_new}>
                    <p>Как происходит покупка:</p>
                    <ul>
                      <li><CheckCircleOutlined/> Регистрируетесь на сайте  <a href="https://ta-patissiere.com/create"> ta-patissiere.com</a></li>
                      <li><CheckCircleOutlined/> Производите оплату по реквизитам
                      <span className={styles.rek}>4400 4301 5528 2145</span>
                      <span className={styles.rek}>MADINA EVLOEVA</span></li>
                      <li><CheckCircleOutlined/> Отправляете квитанцию об оплате и почту, по которой зарегистировались в WhatsApp на номер <a target="_blank" rel="noreferrer" href="https://wa.me/+77079333095">+7 (707) 933 30 95</a></li>
                      <li><CheckCircleOutlined/> После завершения продаж (6 дней) у Вас появится данный курс в личном кабинете</li>
                    </ul>
            </div>
            <div className={styles.my_coursce_d}>
            <p>Стоимость данного курса <b>59 900 тенге</b> <i>49 900 тенге</i>           <span>Только 2 дня цена по акции с обратной связью от меня в течении месяца, после будет повышение цены.</span></p>
  
            </div>
            </Col>
        </Row>

      </div>
      </div>

      <div className={styles.my_contacts} id="contacts">
        <div className={styles.title_bl}>
          <h4>Контакты</h4>
          <span className={styles.landing_title}/>
        </div>
        <div className="container">
          <Row>

            <Col lg={12} md={24} xs={24}>
              <Form
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                autoComplete="off"
                onFinish={onFinish}
                form={form}
              >
                <Form.Item
                  label="ФИО"
                  name="fio"
                  rules={[{required: true, message: 'Введите ФИО!'}]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  label="Номер телефона"
                  name="phone"
                  rules={[{required: true, message: 'Введите номер телефона!'}]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  label="Описание"
                  name="description"
                  rules={[{required: true, message: 'Введите описани!'}]}
                >
                  <TextArea rows={4}/>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={load}>
                    Отправить
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col lg={12} md={24}>
              <div className={styles.cont_righ}>
                <div><EnvironmentOutlined/><p><span>Адрес</span>г. Алматы, Алатауский район, Темирказык 4</p></div>
                <div><PhoneOutlined/><p><span>Телефон</span>+7 (707) 933-30-95</p></div>
                <div><MailOutlined/><p><span>Почта</span><a
                  href="mailto:info@ta-patissiere.com">info@ta-patissiere.com</a></p></div>
              </div>
            </Col>
          </Row>

        </div>
      </div>
      <Footer/>
    </div>

  );
}

export default observer(Home);
