import React, {useState, useEffect} from "react";
import styles from "../Header/Header.module.scss";
import {Anchor, Col, Row, Drawer} from 'antd';
import logo from "../../assets/images/logo2.svg";
import LoginWidget from "../Auth/LoginWidget";
import AppStore from "../App/AppStore";
import { Link } from "react-router-dom";
import { MenuFoldOutlined} from '@ant-design/icons';
import { observer } from "mobx-react-lite";
import userStore from "../Auth/userStore";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className={scroll ? styles.header + " " + styles.header_fix : styles.header + " header"}>
      <div className="container">
        <Row>
          <Col span={6} className={styles.flex_dd}>
            <div className={styles.logo}>
              <Link to="/"><img src={logo} alt="logo"/></Link>
            </div>
          </Col>
          <Col span={18} className={styles.textright}>
            {AppStore.width <= 1024 ? (

              <>
                <p className={styles.but_menu} onClick={showDrawer}>
                  <MenuFoldOutlined/>
                </p>
                <Drawer placement="right" onClose={onClose} title={<LoginWidget/>} open={open}>
                  <Anchor
                    affix={false}

                    items={[
                      {
                        key: '1',
                        href: '/#main',
                        title: 'Главная',
                      },
                      {
                        key: '2',
                        href: '/#about_author',
                        title: 'Об авторе',
                      },
                      {
                        key: '3',
                        href: '/#kurs',
                        title: 'Курс',
                      },
                      {
                        key: '4',
                        href: '/#programm',
                        title: 'Программа',
                      },
                      {
                        key: '5',
                        href: '/#rew',
                        title: 'Отзывы',
                      },
                      {
                        key: '6',
                        href: '/#services',
                        title: 'Мои услуги',
                      },
                      {
                        key: '7',
                        href: '/#contacts',
                        title: 'Контакты',
                      },
           
                    ]}
                  />
                  {userStore?.userData?.role?.admin && <div className="ant-anchor-link"><Link to="/admin">Админ</Link></div>}
                  
                </Drawer>
              </>
            ) : (
              <div className={styles.menu}>
              {userStore?.userData?.role?.admin ? (<li><Link to="/admin">Админ</Link></li>) :

                (<Anchor
                  affix={false}

                  items={[
                    {
                      key: '1',
                      href: '/#main',
                      title: 'Главная',
                    },
                    {
                      key: '2',
                      href: '/#about_author',
                      title: 'Об авторе',
                    },
                    {
                      key: '3',
                      href: '/#kurs',
                      title: 'Курс',
                    },
                    {
                      key: '4',
                      href: '/#programm',
                      title: 'Программа',
                    },
                    {
                      key: '5',
                      href: '/#rew',
                      title: 'Отзывы',
                    },
                    {
                      key: '6',
                      href: '/#services',
                      title: 'Мои услуги',
                    },
                    {
                      key: '7',
                      href: '/#contacts',
                      title: 'Контакты',
                    },
                  ]}
                />)}
                <li><LoginWidget/></li>
              </div>
            )}
          </Col>

        </Row>
      </div>
    </div>

  );
};

export default observer(Header);
