import {Outlet} from "react-router-dom";
import React from "react";

const AuthLayout = () => {
  return (
    <div  className="block_login">
      <Outlet />
    </div>
  );
}

export default AuthLayout;