import {Link, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Button, Popconfirm, Table} from "antd";
import {apiQuery} from "../../Utils";
import styles from "../Admin/Admin.module.scss";
import {PlusOutlined} from "@ant-design/icons";

const AdminAccess = () => {

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadDel, setLoadDel] = useState({});

  const enterLoadDel = (index, v) => {
    setLoadDel((prevLoadings) => {
      const newLoadings = {...prevLoadings};
      newLoadings[index] = v;
      return newLoadings;
    });
  };

  const accessList = useCallback((page = 1, pageSize = 10) => {
    setLoad(true)
    apiQuery("/admin/access", {
      method: 'GET',
      body: {
        page,
        pageSize
      }
    })
      .then((data) => {
        setItems(data.items);
        setItemsCount(data.itemsCount);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
      })
  }, []);

  const accessDel = useCallback((accessId) => {
    enterLoadDel(accessId, true)
    apiQuery("/admin/access", {
      method: 'DELETE',
      body: {
        accessId
      }
    })
      .then((data) => {
        accessList(page);
        enterLoadDel(accessId, false)
      })
      .catch((error) => {
        enterLoadDel(accessId, false)
      })
  }, [accessList, page]);

  const columns = useMemo(() => [
    {
      title: 'Пользователь',
      dataIndex: 'user',
      key: 'user',
      render: (text, record, index) => {
        return <>{record.userIdData.displayName} [{record.userIdData.email}]</>;
      },
    },
    {
      title: 'Курс',
      dataIndex: 'course',
      key: 'course',
      render: (text, record, index) => {
        return <>{record.coursesIdData.name}</>;
      },
    },
    {
      title: 'Дата предоставления доступа',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        return <>{record.createdAt}</>;
      },
    },
    {
      title: 'Дата завершения',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        return <>{record.dateDelete}</>;
      },
    },
    {
      title: '',
      dataIndex: 'address',
      key: 'address',
      render: (text, record, index) => {
        return (
          <Popconfirm
            title="Удалить доступ?"
            onConfirm={()=>{accessDel(record._id)}}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loadDel[record._id]}>
              Удалить
            </Button>
          </Popconfirm>

        );
      },
    },
  ], [accessDel, loadDel]);

  useEffect(() => {
    accessList()
  }, [accessList])

  return (
    <div className="container">

      <Breadcrumb className="breadcrumb_wrp" style={{marginTop: 20}}>
        <Breadcrumb.Item>
          <Link to="/admin">Админ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Доступы
        </Breadcrumb.Item>
      </Breadcrumb>

      <div class="wrp_fl">
        <h4 className="title_add">Доступы</h4>

        <div className={styles.add_button}>
          <Button type="primary" icon={<PlusOutlined/>} onClick={() => {
            navigate('/admin/access/add', {replace: true});
          }}>
            Добавить доступ
          </Button>
        </div>
      </div>

      <Table dataSource={items}
             columns={columns}
             loading={load}
             scroll={{
               x: 768,
             }}
             pagination={{
               pageSize: 5,
               total: itemsCount,
               current: page,
               onChange: (page, pageSize) => {
                 setPage(page);
                 accessList(page, pageSize);
               },
             }}
      />


    </div>

  )

}

export default AdminAccess;