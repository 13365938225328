import React, {useCallback, useEffect, useState} from "react";
import styles from "./AddCourse.module.scss";
import {Breadcrumb, Divider, Popconfirm, Select, Space, Spin} from 'antd';
import {message, Upload} from 'antd';
import {FileAddFilled, MinusCircleOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, Form, Input} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Link, useParams} from "react-router-dom";
import {apiQuery, apiQueryError, fileChunkUpload} from "../../Utils";
import {useForm} from "antd/es/form/Form";

const {TextArea} = Input;
const {Option} = Select;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const videoBeforeUpload = (file) => {
  // const isMpeg = file.type === 'video/ogg';
  const isMpeg = file.type === 'video/mp4';
  // const isMpeg = true;
  if (!isMpeg) {
    message.error('Вы можете загружать только файлы mp4 (video/mp4)!');
  }
  const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Видео должно быть меньше 2ГБ.');
  }
  return isLt2M || isMpeg || Upload.LIST_IGNORE;
};

const Lessons = ({
                   courseId, index, lessonId, cb = () => {
  }
                 }) => {

  const [form] = useForm();
  const [loadings, setLoadings] = useState({lessonsGet: true});
  const [videoList, setVideoList] = useState([]);
  const [docsList, setDocsList] = useState([]);

  const enterLoading = (index, v) => {
    setLoadings((prevLoadings) => {
      const newLoadings = {...prevLoadings};
      newLoadings[index] = v;
      return newLoadings;
    });
  };

  const lessonsGet = useCallback((lessonId) => {
    if (!lessonId) return;
    enterLoading('lessonsGet', true);
    apiQuery("/admin/lessons/" + lessonId)
      .then((data) => {
        enterLoading('lessonsGet', false);
        form.setFieldsValue({name: data.name});
        form.setFieldsValue({description: data.description});
        if (data.video) {
          setVideoList([
            {
              fileId: data.video._id,
              uid: data.video._id,
              name: data.video.data.name,
              url: 'https://ta-patissiere.com/api/file/' + data.video._id,
            }
          ]);
        }
        if (data.docs) {
          const d = [];
          for (let doc of data.docs) {
            d.push({
              fileId: doc._id,
              uid: doc._id,
              name: doc.data.name,
              url: 'https://ta-patissiere.com/api/file/' + doc._id,
            })
          }
          setDocsList(d);
        }
      })
      .catch((error) => {
        apiQueryError({error})
      })
  }, [form])
  const lessonsDel = useCallback(() => {
    if (!lessonId) return;
    enterLoading('lessonsDel', true);
    apiQuery("/admin/lessons", {
      method: 'DELETE',
      body: {
        lessonId,
        courseId
      }
    })
      .then((data) => {
        cb({coursesGet: courseId})
        enterLoading('lessonsDel', false);
      })
      .catch((error) => {
        enterLoading('lessonsDel', false);
      })
  }, [courseId, lessonId, cb])
  const lessonsSave = (values) => {
    console.log(values)
    if (!lessonId) return;
    enterLoading('lessonsSave', true);

    values.videoList = videoList;
    values.docsList = docsList;

    apiQuery("/admin/lessons/" + lessonId, {
      method: 'POST',
      body: values
    })
      .then((data) => {
        enterLoading('lessonsSave', false);
        lessonsGet(lessonId)
        message.success('Урок сохранен')
      })
      .catch((error) => {
        enterLoading('lessonsSave', false);
      })
  };

  const handleChange0 = ({file, fileList}) => {
    setVideoList(fileList);
    console.log({file, fileList})
    if (file.status === 'uploading') {
      enterLoading(0, true);
      return;
    }
    if (file.status === 'done') {
      enterLoading(0, false);
    }
  };
  const handleChange1 = ({file, fileList}) => {
    console.log({file, fileList})
    setDocsList(fileList)
    if (file.status === 'uploading') {
      enterLoading(1, true);
      return;
    }
    if (file.status === 'done') {
      enterLoading(1, false);
    }
  };

  useEffect(() => {
    lessonsGet(lessonId)
  }, [index, lessonId, lessonsGet])

  if (loadings[lessonId]) return <Spin/>

  return <Form form={form}
               labelCol={{
                 span: 24,
               }}
               wrapperCol={{
                 span: 24,
               }}
               autoComplete="off"
               onFinish={lessonsSave}
  >
    <h3>Урок № {index + 1}</h3>
    <Form.Item
      name={'name'}
      rules={[
        {
          required: true,
          message: 'Введите наименование урока',
        },
      ]}
      label="Название урока"
    >
      <Input/>
    </Form.Item>
    <Form.Item
      label="Описание урока"
      name={'description'}
    >
      <TextArea rows={4}/>
    </Form.Item>

    <Form.Item
      label="Прикрепить видео"
      name={'video'}
    >
      <Upload
        action="https://ta-patissiere.com/api/upload/chunk"
        onChange={handleChange0}
        fileList={videoList}
        showUploadList={true}
        maxCount={1}
        beforeUpload={videoBeforeUpload}
        withCredentials={true}
        data={{status:1}}
        customRequest={fileChunkUpload}
      >
        <Button loading={loadings[0]} icon={<FileAddFilled/>}> Загрузить</Button>
      </Upload>
    </Form.Item>

    <Form.Item
      label="Прикрепить дополнительные материалы"
      name={'docs'}
    >
      <Upload
        action="https://ta-patissiere.com/api/upload"
        onChange={handleChange1}
        fileList={docsList}
        showUploadList={true}
      >
        <Button loading={loadings[1]} icon={<FileAddFilled/>}> Загрузить</Button>
      </Upload>
    </Form.Item>
    <Space>
      <Popconfirm
        title="Удалить урок?"
        onConfirm={() => {
          lessonsDel()
        }}
        okText="Да"
        cancelText="Нет"
      >
        <Button loading={loadings['lessonsDel']} icon={<MinusCircleOutlined/>}> Удалить</Button>
      </Popconfirm>

      <Button type="primary"
              htmlType="submit"
              loading={loadings['lessonsSave']}
              icon={<SaveOutlined/>}
              // onClick={() => {
              //   form.submit()
              // }}
      > Сохранить изменения</Button>
    </Space>
  </Form>
}

const AdminCoursesEdit = () => {

  let {id} = useParams();
  const [form] = useForm();
  const [load, setLoad] = useState(false);
  const [loadings, setLoadings] = useState({});
  const [cover, setCover] = useState();
  const [lessons, setLessons] = useState([]);
  const [fileList, setFileList] = useState([]);

  const enterLoading = (index, v) => {
    setLoadings((prevLoadings) => {
      const newLoadings = {...prevLoadings};
      newLoadings[index] = v;
      return newLoadings;
    });
  };

  const onFinish = (values) => {
    setLoad(true);
    values.cover = cover;
    console.log('Success:', values);
    apiQuery("/admin/courses/" + id, {
      method: 'POST',
      body: values
    })
      .then((data) => {
        setLoad(false);
        message.success('Курс сохранен');
        coursesGet()
      })
      .catch((error) => {
        setLoad(false);
      })
  };

  const coverHandleChange = ({file, fileList}) => {
    setFileList(fileList)
    if (file.status === 'uploading') {
      enterLoading('cover', true);
      return;
    }
    if (file.status === 'done') {
      if (file.response) {
        setCover(file.response._id)
      }
      enterLoading('cover', false);
    }
  };

  const uploadButton = (
    <div>
      {loadings['cove'] ? <LoadingOutlined/> : <PlusOutlined/>}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const coursesGet = useCallback((id) => {
    if (!id) return;
    setLoad(true)
    apiQuery("/admin/courses/" + id)
      .then((data) => {
        setLoad(false);
        form.setFieldsValue({name: data.name});
        form.setFieldsValue({description: data.description});
        form.setFieldsValue({status: data.status});
        setCover(data.cover);
        setLessons(data.lessonsIdData || [])
      })
      .catch((error) => {
        setLoad(false);
      })
  }, [form])
  const lessonsAdd = useCallback(() => {
    if (!id) return;
    enterLoading('lessonsAdd', true);
    apiQuery("/admin/lessons/", {
      method: 'POST',
      body: {
        courseId: id
      }
    })
      .then((data) => {
        coursesGet(id)
        enterLoading('lessonsAdd', false);
      })
      .catch((error) => {
        enterLoading('lessonsAdd', false);
      })
  }, [id, coursesGet])

  useEffect(() => {
    coursesGet(id)
  }, [id, form, coursesGet])

  if (!id) return <></>

  return (
    <>
      <div className={styles.admin_wp}>
        <div className="container">

          <Breadcrumb className="breadcrumb_wrp" style={{marginTop: 20}}>
            <Breadcrumb.Item>
              <Link to="/admin">Админ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/admin">Курсы</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Редактировать курс</Breadcrumb.Item>
          </Breadcrumb>

          <h4 className="title_add">Редактировать курс</h4>

          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Название Курса"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Введите название курса!',
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Описание курса"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Введите описание курса!',
                },
              ]}
            >
              <TextArea rows={4}/>
            </Form.Item>

            <Form.Item
              label="Обложка курса"
              name="cover"
            >
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://ta-patissiere.com/api/upload"
                beforeUpload={beforeUpload}
                onChange={coverHandleChange}
                fileList={fileList}
              >
                {cover ? (
                  <img
                    src={'http://ta-patissiere.com/api/file/' + cover}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item name="status" label="Статус">
              <Select>
                <Option value={0}>Не активен</Option>
                <Option value={1}>Активен</Option>
              </Select>
            </Form.Item>

          </Form>

          <Form.Item>
            <Button type="primary" loading={load} htmlType="submit" onClick={() => {
              form.submit()
            }}>
              Сохранить курс
            </Button>
          </Form.Item>

          <Divider/>

          {lessons.map((lessonId, index) => <div key={index} className={styles.one_course}>
            <Lessons courseId={id} index={index} lessonId={lessonId} cb={(d) => {
              if (d.coursesGet) coursesGet(d.coursesGet);
            }}/>
          </div>)}

          <Form.Item>
            <Button type="primary"
                    loading={loadings['lessonsAdd']}
                    icon={<PlusOutlined/>}
                    onClick={() => {
                      lessonsAdd()
                    }}
            >
              Добавить урок
            </Button>
          </Form.Item>

        </div>
      </div>
    </>
  );
};

export default AdminCoursesEdit;
