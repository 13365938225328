import React from "react";
import userStore from "./userStore";
import {Spin} from "antd";
import { LoginOutlined } from '@ant-design/icons';
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";

const LoginWidget = () => {
  const auth = userStore.auth;

  if (auth === null) return <><Spin /></>
  if (!auth) return <Link to="/login"><LoginOutlined /> Вход</Link>
  return <><Link to="/cabinet"><LoginOutlined /> Кабинет</Link> <span onClick={() => {
    userStore.logout()
  }}>/ Выход</span></>
}

export default observer(LoginWidget);