import React from "react";
import logo from "../../assets/images/logo_white.svg";
import { Form, Input, Button, Checkbox, Layout } from "antd";
import "./Login.scss";
import { Link } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";
import userStore from "./userStore";

const { Content } = Layout;

const Login = () => {
  const onFinish = (values) => {
    apiQuery("/login", {
      method: "POST",
      body: values,
    })
      .then((userData) => {
        userStore.setUserData(userData);
        userStore.setAuth(true);
        window.location.href = "/";
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  return (
    <>
      <a className="logo_auth" href={"/"}>
          <img src={logo} alt="logo" />
        </a>
    <Content
      className="site-layout form_content">
      <div> 
        <h3 className="title_auth">Войти в аккаунт</h3>
      </div>

      <Form
        name="normal_login"
        className="login-form mt-32"
        initialValues={{ rememberMe: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Введите email!" }]}
        >
          <Input type={"email"} placeholder="Электронный адрес" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Введите пароль!" }]}
        >
          <Input type="password" placeholder="Пароль" />
        </Form.Item>
          <Form.Item name="rememberMe" valuePropName="checked"  className="block_pass">
            <Checkbox>Запомнить меня</Checkbox>
            <Link className="login-form-forgot" to="/restore">
            Забыли пароль?
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
          >
            Войти
          </Button>
        </Form.Item> 
      </Form>
      <p className="no_acc mb-24">
          Нет аккаунта?
          <Link to="/create">Создать</Link>
        </p>
    </Content>
    </>
  );
};

export default Login;
