import {Breadcrumb, Table} from "antd";
import {Link} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {apiQuery} from "../../Utils";
import moment from 'moment';

const columns = [
  {
    title: 'ФИО',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (text, record, index) => {
      return <>{record.displayName}</>;
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text, record, index) => {
      return <>{record.email}</>;
    },
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'date',
    key: 'date',
    render: (text, record, index) => {
      return <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>;
    },
  },
];

const AdminUsers = () => {

  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [load, setLoad] = useState(false);

  const usersList = useCallback((page = 1, pageSize = 10) => {
    setLoad(true)
    apiQuery("/admin/users", {
      method: 'GET',
      body: {
        page,
        pageSize
      }
    })
      .then((data) => {
        setItems(data.items);
        setItemsCount(data.itemsCount);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
      })
  }, []);

  useEffect(() => {
    usersList()
  }, [usersList])

  return <div className="container">
    <Breadcrumb className="breadcrumb_wrp" style={{marginTop: 20}}>
      <Breadcrumb.Item>
        <Link to="/admin">Админ</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Пользователи
      </Breadcrumb.Item>
    </Breadcrumb>

    <Table dataSource={items}
           columns={columns}
           loading={load}
           scroll={{
             x: 768,
           }}
           pagination={{
             pageSize: 5,
             total: itemsCount,
             current: page,
             onChange: (page, pageSize) => {
               setPage(page);
               usersList(page, pageSize);
             },
           }}
    />
  </div>
}

export default AdminUsers;