import {Outlet, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import userStore from "../components/Auth/userStore";
import {Spin} from "antd";
import {observer} from "mobx-react-lite";
import AdminHeader from "../components/AdminHeader/AdminHeader";
import styles from "../scss/CabinetLayout.module.scss";

const AdminLayout = () => {
  let navigate = useNavigate();

  useEffect(() => {
    userStore.authCheck().catch()
  }, []);

  if (userStore.auth === null) {
    return <Spin/>;
  }

  if (!userStore.auth) {
    navigate('/login' , {replace: true});
    return;
  }

  if (!userStore?.userData?.role?.admin) {
    navigate('/' , {replace: true});
    return;
  }

  return (
    <div className={styles.cabinet + " " +styles.cabinet_admin}>
      <AdminHeader/>
      <Outlet />
    </div>
  );
}

export default observer(AdminLayout);