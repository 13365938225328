import React, {useCallback, useEffect, useState} from "react";
import styles from "./Cabinet.module.scss";
import CourseItem from "../CourseItem";
import {message, Pagination, Spin, Tabs} from 'antd';
import {Breadcrumb, Button, Form, Input} from 'antd';
import {apiQuery, apiQueryError} from "../../Utils";
import userStore from "../Auth/userStore";

const Cabinet = () => {

  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoadSave(true);
    apiQuery("/private/userData", {
      method: 'POST',
      body: values
    })
      .then((data) => {
        userStore.authCheck().catch();
        message.success('Данные успешно сохранены')
        setLoadSave(false);
      })
      .catch((error) => {
        apiQueryError({error});
        setLoadSave( false);
      })
  }

  const coursesList = useCallback((page = 1, pageSize = 10) => {
    setLoad(true)
    apiQuery("/private/courses", {
      method: 'GET',
      body: {
        page,
        pageSize
      }
    })
      .then((data) => {
        setItems(data.items);
        setItemsCount(data.itemsCount);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
      })
  }, [])

  useEffect(() => {
    coursesList();
    form.setFieldsValue({displayName: userStore.userData.displayName});
    form.setFieldsValue({email: userStore.userData.email});
  }, [coursesList, form])

  return (
    <>
      <div className={styles.admin_wp}>
        <div className="container">
          <Breadcrumb className="breadcrumb_wrp">
            <Breadcrumb.Item><a href="/">Главная</a></Breadcrumb.Item>
            <Breadcrumb.Item>
              Кабинет
            </Breadcrumb.Item>
          </Breadcrumb>
          <Tabs defaultActiveKey="1" className={styles.tabs_wp}>
            <Tabs.TabPane tab="Все курсы" key="1">
              <h4>Мое обучение</h4>
              {load && <Spin/>}
              {!load && items.length === 0 && <p>У вас нет курсов</p>}
              <div className={styles.flex_course}>
                {items.map((item, index) => <CourseItem key={index} item={item}/>)}
              </div>
              <Pagination defaultCurrent={page} total={itemsCount}
                          onChange={(page, pageSize) => {
                            setPage(page);
                            coursesList(page, pageSize);
                          }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Настройки" key="2">
              <h4>Настройки</h4>
              <Form
                form={form}
                name="basic"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                autoComplete="off"
                onFinish={onFinish}
              >
                <Form.Item
                  label="ФИО"
                  name="displayName"
                  rules={[
                    {
                      required: true,
                      message: 'Введите ФИО',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Введите электронную почту',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  label="Новый пароль"
                  name="password"
                  extra="Введите если необходимо сменить пароль."
                  rules={[
                    {
                      message: 'Введите пароль',
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loadSave}>
                    Сохранить
                  </Button>
                </Form.Item>
              </Form>

            </Tabs.TabPane>
            <Tabs.TabPane tab="Сертификаты" key="3">
              Сертификаты
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Cabinet;
