import React, {useEffect} from "react";
import {Result} from "antd";
import Home from '../../pages/Home'
import { useRoutes } from "react-router-dom";
import HomeLayout from "../../layouts/HomeLayout";
import AuthLayout from "../../layouts/AuthLayout";
import CabinetLayout from "../../layouts/CabinetLayout";
import Login from "../Auth/Login";
import Create from "../Auth/Create";
import Restore from "../Auth/Restore";
import Cabinet from "../Cabinet";
import VerifyEmail from "../Auth/VerifyEmail";
import userStore from "../Auth/userStore";
import AdminLayout from "../../layouts/AdminLayout";
import AdminCoursesAdd from "../AdminCourses/AdminCoursesAdd";
import AdminUsers from "../AdminUsers/AdminUsers";
import AdminSettings from "../AdminSettings/AdminSettings";
import AdminCourses from "../AdminCourses/AdminCourses";
import AdminCoursesEdit from "../AdminCourses/AdminCoursesEdit";
import CourseCard from "../CourseCard";
import AdminAccess from "../AdminAccess/AdminAccess";
import AdminAccessAdd from "../AdminAccess/AdminAccessAdd";
import CourseVideo from "../CourseVideo";
import AppStore from "./AppStore";

function NoMatch() {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Извините, страница, которую вы посетили, не существует. "
        extra={
          <a href={"/"} type="primary">
            На главную
          </a>
        }
      />
    </div>
  );
}

function App() {

  useEffect(() => {
    userStore.authCheck().catch()
  }, []);

  let element = useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "/login", element: <Login /> },
        { path: "/create", element: <Create/> },
        { path: "/restore", element: <Restore/> },
        { path: "/verify_email", element: <VerifyEmail/> },
      ]
    },
    {
      path: "/cabinet",
      element: <CabinetLayout />,
      children: [
        { index: true, element: <Cabinet /> },
        { path: "/cabinet/course/:courseId", element: <CourseCard /> },
        { path: "/cabinet/course/:courseId/:lessonId", element: <CourseVideo /> },
      ]
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { index: true, element: <AdminCourses /> },
        { path: "/admin/courses/add", element: <AdminCoursesAdd /> },
        { path: "/admin/courses/:id", element: <AdminCoursesEdit /> },
        { path: "/admin/users", element: <AdminUsers /> },
        { path: "/admin/settings", element: <AdminSettings /> },
        { path: "/admin/access", element: <AdminAccess /> },
        { path: "/admin/access/add", element: <AdminAccessAdd /> },
      ]
    },
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { index: true, element: <Home /> },
      ]
    },
    { path: "*", element: <NoMatch /> },
  ]);

  return <div>{element}</div>;
}

export default App;

// document.addEventListener("contextmenu", function (e){
//   e.preventDefault();
// }, false);

AppStore.resize(window.innerWidth, window.innerHeight);

(function () {
  window.addEventListener("resize", resizeThrottler, false);

  let resizeTimeout;

  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        actualResizeHandler(this);

        // The actualResizeHandler will execute at a rate of 15fps
      }, 500);
    }
  }

  function actualResizeHandler(e) {
    AppStore.resize(e.innerWidth, e.innerHeight);
  }
})();
