import React, {useCallback, useEffect, useState} from "react";
import styles from "../CourseVideo/CourseVideo.module.scss";
import {Col, Empty, Image, Row, Spin} from 'antd';
import {Breadcrumb} from 'antd';
import {Link, useParams} from "react-router-dom";
import {apiQuery, apiQueryError} from "../../Utils";
// import ReactPlayer from 'react-player';
// import Player from '../Player';
import {LoadingOutlined} from "@ant-design/icons";
import {AutoSizer} from 'react-virtualized';
import {randomColor} from "randomcolor";

const antIcon = <LoadingOutlined style={{fontSize: 36, color: '#fff'}} spin/>;

function useScript(url) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script); 
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
}

// function PlayNewVideo(url) {
//   if (window.pljssglobal.length > 0) {
//     console.log({
//       url,
//       pljssglobal: window.pljssglobal[0]
//     })
//     window.pljssglobal[0].api("play", url);
//   }
// }

function FileItem({file}) {

  if (file.filetype === "image")
    return <Image src={'https://ta-patissiere.com/api/file/' + file._id}
                  style={{width: 100}}
    />;

  return <div className={styles.fileItem}>
    <Row>
      <Col flex={'20px'}>
        <div className={styles.fileType}
             style={{backgroundColor: randomColor({seed: file.ext, luminosity: 'bright'})}}>{file.ext}</div>
      </Col>
      <Col flex={'auto'}>
        <a href={'https://ta-patissiere.com/api/file/' + file._id} target={'_blank'} rel="noreferrer"
           className={styles.fileContent}>
          <AutoSizer disableHeight={true}>
            {({width}) => (
              <p style={{width: width}}>
                {file.name}
              </p>
            )}
          </AutoSizer>
        </a>
      </Col>
    </Row>
  </div>
}

const CourseVideo = () => {
  const {lessonId, courseId} = useParams();
  const [data, setData] = useState();
  const [videoUrl, setVideoUrl] = useState(false);
  const [load, setLoad] = useState(false);

  const lessonGet = useCallback(() => {
    if (!lessonId) return;
    setVideoUrl(false);
    setLoad(true)
    apiQuery("/private/lesson", {
      method: 'POST',
      body: {
        lessonId,
        courseId
      }
    })
      .then((data) => {
        setData(data);
        setTimeout(() => {
          // PlayNewVideo(`https://ta-patissiere.com/api/private/file/${data.lesson.video}/${courseId}/${lessonId}/${data.lesson.videoHash}`)
          // PlayNewVideo(`https://ta-patissiere.com/api/fileTest`)
          setVideoUrl(`https://ta-patissiere.com/playerjs.html?file=https://ta-patissiere.com/api/private/file/${data.lesson.video}/${courseId}/${lessonId}/${data.lesson.videoHash}`);
        }, 1000)
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        apiQueryError({error})
      })
  }, [lessonId, courseId]);

  useScript("/playerjs.js");

  useEffect(() => {
    lessonGet()
  }, [lessonGet, lessonId, courseId]);

  if (load || !data) return (<div className={styles.card_course}>
    <div className="container">
      <Breadcrumb className="breadcrumb_wrp">
        <Breadcrumb.Item><a href="/">Главная</a></Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/cabinet">Кабинет</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Spin/>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Spin/>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin/>
      <div>
        <div id="player2"/>
      </div>
    </div>
  </div>);

  return (
    <div className={styles.card_course}>
      <div className="container">
        <Breadcrumb className="breadcrumb_wrp">
          <Breadcrumb.Item><a href="/">Главная</a></Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/cabinet">Кабинет</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={'/cabinet/course/' + data.course._id}>{data.course.name}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {data.curr} урок
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.video_bl}>
          <div className={styles.pagination_wp}>
            {!data.prev && <Link to={`/cabinet/course/${data.course._id}`}> Вернуться в курс</Link>}
            {data.prev &&
            <Link to={`/cabinet/course/${data.course._id}/${data.prev}`}>Предыдущий урок</Link>}
            {data.next &&
            <Link to={`/cabinet/course/${data.course._id}/${data.next}`}>Следующий урок</Link>}
          </div>

          <h4>{data.lesson.name}</h4>

          {data.lesson.videoStatus !== 0 && <p>Видео ролик на обработке. Появится позже.</p>}

          {data.lesson.videoStatus === 0 && <div style={{maxWidth: 1000, margin: '0 '}}>
            <AutoSizer disableHeight={true}>
              {({width}) => (
                <Spin spinning={!videoUrl}
                      indicator={antIcon}
                      style={{width: width, height: (width / 1.77777)}}
                >
                  <div className={styles.player_bl} style={{
                    height: (width / 1.77777),
                    width: width
                  }}>
                    {videoUrl && !data?.lesson?.video && <Empty
                      image={<svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"
                                  style={{height: 41}}>
                        <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                          <ellipse cx="32" cy="33" rx="32" ry="7" fill="#333333"></ellipse>
                          <g fill-rule="nonzero" stroke="#999999">
                            <path
                              d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                            <path
                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                              fill="#333333"></path>
                          </g>
                        </g>
                      </svg>}
                      imageStyle={{height: 41, color: "#999"}}
                      description={<span style={{color: "#999"}}>Нет видео</span>}
                      style={{
                        paddingTop: (width / 1.77777) / 2 - 20,
                        color: "#999"
                      }}/>}
                    {videoUrl && !!data?.lesson?.video && <iframe
                      title="video"
                      src={videoUrl}
                      // src={`https://ta-patissiere.com/api/fileTest`}
                      type="text/html" width={width} height={width / 1.77777} frameBorder="0"
                      allow="fullscreen"/>}
                  </div>
                </Spin>
              )}
            </AutoSizer>
          </div>}

          {/*<Player id="player2" file={`https://ta-patissiere.com/api/private/file/${data.lesson.video}/${courseId}/${lessonId}/${data.lesson.videoHash}`}/>*/}

          <div className={styles.video_txt}>
          <h3 className={styles.video_titil}>Дополнительная информация</h3>
            <p>{data.lesson.description}</p>
          </div>

          {data.lesson.docs && !!data.lesson.docs.length && <div className={styles.video_txt}>
            {data.lesson.docs.map((item, index) => <span key={index}><FileItem file={item}/></span>)}
          </div>}
        </div>


      </div>
    </div>
  );
};

export default CourseVideo;
