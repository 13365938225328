import React, {useState} from "react";
import styles from "./AddCourse.module.scss";
import {Breadcrumb} from 'antd';
import {message, Upload} from 'antd';
import {Button, Form, Input} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Link, useNavigate} from "react-router-dom";
import {apiQuery} from "../../Utils";

const {TextArea} = Input;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const AdminCoursesAdd = () => {

  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cover, setCover] = useState();

  const onFinish = (values) => {
    setLoad(true);
    values.cover = cover;
    console.log('Success:', values);
    apiQuery("/admin/courses/", {
      method: 'POST',
      body: values
    })
      .then((data) => {
        setLoad(false);
        message.success('Курс создан');
        navigate('/admin/courses/'+data._id , {replace: true});
      })
      .catch((error) => {
        setLoad(false);
      })
  };

  const coverHandleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        setCover(info.file.response._id)
      }
      setLoading(false);
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.admin_wp}>
        <div className="container">

          <Breadcrumb style={{marginTop: 20}}>
            <Breadcrumb.Item>
              <Link to="/admin">Админ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/admin">Курсы</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить курс</Breadcrumb.Item>
          </Breadcrumb>

          <h4>Добавление курса</h4>

          <Form
            name="basic"
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Название Курса"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Введите название курса!',
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Описание курса"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Введите описание курса!',
                },
              ]}
            >
              <TextArea rows={4}/>
            </Form.Item>
            <Form.Item
              label="Обложка курса"
              name="cover"
            >
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://ta-patissiere.com/api/upload"
                beforeUpload={beforeUpload}
                onChange={coverHandleChange}
              >
                {cover ? (
                  <img
                    src={'https://ta-patissiere.com/api/file/' + cover}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={load}>
                Сохранить курс
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AdminCoursesAdd;
