import styles from "../Admin/Admin.module.scss";
import {Breadcrumb, Button, Popconfirm, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {apiQuery} from "../../Utils";
import ImagePreview from "../ImagePreview";

const AdminCourses = () => {

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadDel, setLoadDel] = useState({});

  const enterLoadDel = (index, v) => {
    setLoadDel((prevLoadings) => {
      const newLoadings = {...prevLoadings};
      newLoadings[index] = v;
      return newLoadings;
    });
  };

  const columns = [
    {
      title: 'Обложка',
      dataIndex: 'cover',
      key: 'cover',
      render: (text, record, index) => {
        console.log(record)
        return (
          <ImagePreview src={'http://ta-patissiere.com/api/file/' + record.cover} width={50} height={50}/>
        );
      },
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'address',
      key: 'address',
      render: (text, record, index) => {
        return <Space>
          <Link to={`/admin/courses/${record._id}`} key={index}>
            <Button><EditOutlined /></Button>
          </Link>
          <Popconfirm title="Удалить?" onConfirm={() => handleDelete(record._id)}>
            <Button danger={true} loading={loadDel[record._id]}><DeleteOutlined /></Button>
          </Popconfirm>
        </Space>
      },
    },
  ];

  const handleDelete = (id) => {
    enterLoadDel(id,true);
    apiQuery("/admin/courses/"+id, {
      method: 'DELETE'
    })
      .then((data) => {
        coursesList(page)
        enterLoadDel(id,false);
      })
      .catch((error) => {
        enterLoadDel(id,false);
      })
  };

  const coursesList = useCallback((page = 1, pageSize = 10) => {
    setLoad(true)
    apiQuery("/admin/courses", {
      method: 'GET',
      body: {
        page,
        pageSize
      }
    })
      .then((data) => {
        setItems(data.items);
        setItemsCount(data.itemsCount);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
      })
  }, [])

  useEffect(() => {
    coursesList()
  }, [coursesList]);

  return <div className="container">

      <Breadcrumb className="breadcrumb_wrp" style={{marginTop: 20}}>
        <Breadcrumb.Item>
          <Link to="/admin">Админ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Курсы
        </Breadcrumb.Item>
      </Breadcrumb>

      <div class="wrp_fl">

      <h4  className="title_add">Мои курсы</h4>

      <div className={styles.add_button}>
        <Button type="primary" icon={<PlusOutlined/>} onClick={() => {
          navigate('/admin/courses/add', {replace: true});
        }}>
          Добавить курс
        </Button>
      </div>
  </div> 
      <div className={styles.flex_course}>
        <Table dataSource={items}
               columns={columns}
               loading={load}
               pagination={{
                 pageSize: 5,
                 total: itemsCount,
                 current: page,
                 onChange: (page, pageSize) => {
                   setPage(page);
                   coursesList(page, pageSize);
                 },
               }}
        />
      </div>
    </div>

}

export default AdminCourses;