import React from "react";
import logo from "../../assets/images/logo.svg";
import { Form, Input, Button, Layout, message } from "antd";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { apiQuery, apiQueryError } from "../../Utils";

const { Content } = Layout;

const Create = () => {
  let navigate = useNavigate();

  const onFinish = (values) => {
    apiQuery("/create_user", {
      method: "POST",
      body: values,
    })
      .then((userData) => {
        message.success("Вы успешно зарегистрированны. Авторизуйтесь");
        // history.push("/login");
        navigate('/login' , {replace: true});
      })
      .catch((error) => {
        apiQueryError({ error });
      });
  };

  return (
    <>        
    <a className="logo_auth" href={"/"}>
    <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
  </a>
    <Content
      className="site-layout login-form form_content" >
      <div>
        <h3 className="title_auth">Создать аккаунт</h3>
      </div>

      <Form
        name="normal_login"
        className="login-form mt-32"
        onFinish={onFinish}
        layout={"vertical"}
      >
        <Form.Item
          name="displayName"
          rules={[{ required: true, message: "Введите ФИО!" }]}
        >
          <Input placeholder="ФИО" />
        </Form.Item>

        {/*<Form.Item*/}
        {/*  name="phone"*/}
        {/*  rules={[{ required: true, message: "Введите номер телефона!" }]}*/}
        {/*>*/}
        {/*  <Input*/}
        {/*    type={"tel"}*/}
        {/*    prefix={<PhoneOutlined className="site-form-item-icon" />}*/}
        {/*    placeholder="Номер телефона"*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Введите email!" }]}
        >
          <Input type={"email"} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Введите пароль!" }]}
        >
          <Input type="password" placeholder="Пароль" />
        </Form.Item>
        <p className="little_text2 mb-16 text_center">
          Регистируясь Вы соглашаетесь с нашим <a target="_blank" href="/page/polzovatelskoe-soglashenie">
            пользовательским соглашением
          </a>
        </p>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
          >
            Создать аккаунт
          </Button>
        </Form.Item>
      </Form>
      <p className="no_acc1 mb-24">
          Уже зарегистрирован?
          <Link to="/login">Войти</Link>
        </p>
    </Content>
    </>
  );
};

export default Create;
