import {configure, makeAutoObservable} from "mobx";

configure({enforceActions: "never"})

class AppStore {

  width = 0;
  height = 0;

  constructor() {
    makeAutoObservable(this)
  }

  resize(width, height){
    this.width = width;
    this.height = height;
  }

}

export default new AppStore()