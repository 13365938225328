import {Outlet, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import userStore from "../components/Auth/userStore";
import {Spin} from "antd";
import {observer} from "mobx-react-lite";
import Header from "../components/Header";
import styles from "../scss/CabinetLayout.module.scss";

const CabinetLayout = () => {
  let navigate = useNavigate();

  useEffect(() => {
    userStore.authCheck().catch()
  }, []);

  if (userStore.auth === null) {
    return <Spin/>;
  }

  if (!userStore.auth) {
    navigate('/login' , {replace: true});
    return;
  }

  if (!userStore?.userData?.emailVerified) {
    navigate('/verify_email', {replace: true})
    return null;
  }

  return (
    <div className={styles.cabinet}>
      <Header/>
      <Outlet />
    </div>
  );
}

export default observer(CabinetLayout);