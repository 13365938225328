import React from "react";
import styles from "../CourseItem/CourseItem.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";

const CourseItem = ({item}) => {
   return (
    <Link to={'/cabinet/course/' + item.coursesIdData._id} className={styles.course_wp}>
    <div className={styles.course_bg} style={{backgroundImage: `url(https://ta-patissiere.com/api/file/${item.coursesIdData.cover})` }}>
    <span>Осталось: {moment(item.dateDelete).diff(moment(), 'days')} дней</span>
    </div>
    <div className={styles.course_text}>
        <p>{item.coursesIdData.name}</p>
        <span>{item.coursesIdData.lessonsIdData.length} уроков</span>
    </div>
    </Link>
    
  );
};

export default CourseItem;
