import {Breadcrumb, Button, DatePicker, Form, message, Select} from "antd";
import {Link, useNavigate} from "react-router-dom";
import styles from "../AdminCourses/AddCourse.module.scss";
import React, {useCallback, useEffect, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(weekday);
dayjs.extend(localeData);

const AdminAccessAdd = () => {

  const navigate = useNavigate();
  const [loadings, setLoadings] = useState({});
  const [selectUsers, setSelectUsers] = useState([]);
  const [selectCourses, setSelectCourses] = useState([]);

  const enterLoading = (index, v) => {
    setLoadings((prevLoadings) => {
      const newLoadings = {...prevLoadings};
      newLoadings[index] = v;
      return newLoadings;
    });
  };

  const usersList = useCallback(() => {
    enterLoading('usersList', true)
    apiQuery("/admin/searchUsers", {
      method: 'POST',
    })
      .then((data) => {
        setSelectUsers(data);
        enterLoading('usersList', false);
      })
      .catch((error) => {
        enterLoading('usersList', false);
      })
  }, [])
  const coursesList = useCallback(() => {
    enterLoading('coursesList', true)
    apiQuery("/admin/searchCourses", {
      method: 'POST',
    })
      .then((data) => {
        setSelectCourses(data);
        enterLoading('coursesList', false);
      })
      .catch((error) => {
        enterLoading('coursesList', false);
      })
  }, [])

  const onFinish = (values) => {
    enterLoading('accessSave', true);

    apiQuery("/admin/accessSave", {
      method: 'POST',
      body: values
    })
      .then((data) => {
        enterLoading('accessSave', false);
        message.success('Доступ создан');
        navigate('/admin/access', {replace: true});
      })
      .catch((error) => {
        enterLoading('accessSave', false);
        apiQueryError({error})
      })
  };

  useEffect(() => {
    usersList();
    coursesList();
  }, [usersList, coursesList])

  return (
  <div className={styles.admin_wp}>

  <div className="container">

    <Breadcrumb className="breadcrumb_wrp" style={{marginTop: 20}}>
      <Breadcrumb.Item>
        <Link to="/admin">Админ</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/admin/access">Доступы</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Дабавить доступ
      </Breadcrumb.Item>
    </Breadcrumb>

    <h4 className="title_add">Добавить доступ</h4>

    <Form
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      // initialValues={worker}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Пользователь"
        name="user"
        rules={[{ required: true, message: 'Выберите пользователя' }]}
      >
        <Select
          loading={loadings['usersList']}
          showSearch
          placeholder="Выберите пользователя"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={selectUsers}
        />
      </Form.Item>

      <Form.Item
        label="Курс"
        name="course"
        rules={[{ required: true, message: 'Выберите курс' }]}
      >
        <Select
          loading={loadings['coursesList']}
          showSearch
          placeholder="Выберите курс"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={selectCourses}
        />
      </Form.Item>

      <Form.Item
        label="Дата отключения доступа к курсу"
        name="dateDelete"
        initialValue={dayjs().add(1, 'year')}
        rules={[{ required: true, message: 'Выберите дату' }]}
      >
        <DatePicker format={"YYYY-MM-DD"}/>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Добавить
        </Button>
      </Form.Item>

    </Form>

  </div>
 
  </div>
  )
}

export default AdminAccessAdd;