import React, {useState} from "react";
import styles from "./AdminHeader.module.scss";
import {Col, Row, Drawer} from "antd";
import {Link} from "react-router-dom";
import AppStore from "../App/AppStore";
import logo from "../../assets/images/logo2.svg";
import { observer } from "mobx-react-lite";
import { MenuFoldOutlined} from '@ant-design/icons';


const AdminHeader = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return <div className={styles.header}>
    <div className="container">
      <Row>
        <Col  className={styles.flex_dd} span={6}>
          <div className={styles.logo}>
            <Link to="/"><img src={logo} alt="logo"/></Link>
          </div>
        </Col>
        {AppStore.width <= 768 ? (
          <>
          <Col span={6} className={styles.textright} >
           <p className={styles.but_menu} onClick={showDrawer}>
                  <MenuFoldOutlined/>
                </p>
                </Col>
          <Drawer placement="right" onClose={onClose}  open={open}>
                <ul className={styles.menu}>
            <li><Link to="/admin" onClick={() => {
setOpen(false) }}>Курсы</Link></li>
            <li><Link to="/admin/users" onClick={() => {
setOpen(false) }}>Пользователи</Link></li>
            {/*<li><Link to="/admin/settings" onClick={() => {
setOpen(false) }}>Настройки</Link></li>*/}
            <li><Link to="/admin/access" onClick={() => {
setOpen(false) }}>Доступ</Link></li>
 
            <li><Link to="/" onClick={() => {
setOpen(false) }}>Перейти на сайт</Link></li>
   <li><Link to="/cabinet" onClick={() => {
setOpen(false) }}>Перейти на пользователя</Link></li>
          </ul>
                </Drawer>
               </>

          ) : (
        <Col span={18} className={styles.textright} >
          <ul className={styles.menu}>
            <li><Link to="/admin">Курсы</Link></li>
            <li><Link to="/admin/users">Пользователи</Link></li>
           {/*<li><Link to="/admin/settings">Настройки</Link></li>*/}
            <li><Link to="/admin/access">Доступ</Link></li>    
            <li><Link to="/">Перейти на сайт</Link></li>
            <li><Link to="/cabinet" onClick={() => {
setOpen(false) }}>Перейти на пользователя</Link></li>
          </ul>
        </Col>
        )}
      </Row>
    </div>
  </div>
}

export default observer(AdminHeader);