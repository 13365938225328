import logo from "../../assets/images/logo.svg";
import {Button, Form, message} from "antd";
import ReactCodeInput from "react-code-input";
import {Link} from "react-router-dom";
import React, {useRef, useState} from "react";
import {Content} from "antd/es/layout/layout";
import {apiQuery, apiQueryError} from "../../Utils";

const propsReactCodeInput = {
  inputStyle: {
    margin: 6,
    width: "16%",
    fontSize: "22px",
    height: 40,
    padding: "4px 8px",
    borderRadius: "4px",
    border: "1px solid #d9d9d9",
    textAlign: "center",
  },
};

const Resend = () => {

  const [sec, setSec] = useState(0);
  const [sendCheck, setSendCheck] = useState(true);
  const timer = useRef()

  const funTimer = (s) => {
    clearInterval(timer.current)
    setSec(s)
    timer.current = setInterval(() => {
      if (s === 0) {

        apiQuery("/mailVerificationCodeSend")
        .then(() => {
          message.success('Письмо отправлено').then()
        })
        .catch((error) => {
          apiQueryError({error});
        });

        clearInterval(timer.current)
        return;
      }
      if (s === 1) {
        setSendCheck(true)
      }
      s--;
      setSec(s)
    }, 1000)
  }

  if (!sendCheck)
    return <p style={{textAlign: "center"}}>Письмо отправлено. Повторно отправить можно через: {sec} сек.</p>

  return <div style={{textAlign: "center"}}>
    <Button type="link" style={{textAlign: "center", cursor: "pointer"}}
            onClick={() => {
              funTimer(30)
              setSendCheck(false)
            }}
            className="little_text2">Отправить повторно код на email</Button>
  </div>
}

const VerifyEmail = () => {

  const [code, setCode] = useState('')

  const onFinish = () => {

    if (code === '') return;

    apiQuery("/mailVerification", {
      method: "POST",
      body: {
        code,
      },
    })
    .then(() => {
      message.success('Почта успешно подтверждена').then()
      setTimeout(()=>{
        window.location.href = '/';
      }, 1000)
    })
    .catch((error) => {
      apiQueryError({error});
    });
  }
  const onChange = (v) => {
    console.log(v)
    setCode(v)
  }

  return (
    <>
    <a className="logo_auth" href={"/"}>
    <img src={logo} style={{ marginBottom: "20px" }} alt="logo" />
  </a>
      <Content
          className="site-layout form_content"
      >
        <div style={{ textAlign: "center" }}>
          <h3 className="title_auth">Подтверждение почты</h3>
          <p className="little_text2">На Ваш электронный адрес отправлен проверочный код, введите его:</p>
        </div>

        <Form
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
        >
          <div
              style={{
                display: "block",
                margin: "20px auto",
                width: "100%",
              }}
              className="input_gr"
          >
            <ReactCodeInput
                type="number"
                fields={6}
                name="code"
                value={code}
                onChange={onChange}
                autoComplete={"off"}
                {...propsReactCodeInput}
            />
          </div>

          <Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                block
            >
              Отправить
            </Button>
          </Form.Item>

          <Resend/>

        <p className="no_acc mb-24">
          <Link className="gray_button" to="/login">
              Авторизоваться
            </Link>
        </p>
        </Form>
      </Content>
      </>
  );
}

export default VerifyEmail;