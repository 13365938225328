import { message } from 'antd';
import userStore from "./components/Auth/userStore";
import CryptoJS from 'crypto-js';
/**
 * Мобильный или нет
 * @type {boolean}
 */
export const mobileCheck = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

/**
 * Обработка
 * @param data
 * @param company
 * @returns {{getUrl: string, getUrlData: string, getUrlDownload: string, getUrlFilename: string}|{getUrl: *, getUrlData: *, getUrlDownload: *, getUrlFilename: *}}
 */
export function fileAdapted(data, company){

  if (data.fileUrl)
    return {
      getUrl        : data.fileUrl,
      getUrlData    : data.fileUrl,
      getUrlDownload: data.fileUrl,
      getUrlFilename: data.fileUrl
    }

  if(data.serverDomain === undefined || data.id === undefined)
    return {
      getUrl        : '',
      getUrlData    : '',
      getUrlDownload: '',
      getUrlFilename: ''
    }

  if (data.public === false)
    return {
      getUrl        : data.serverDomain + '/file/' +company.serverStaticToken+'/'+ data.id,
      getUrlData    : data.serverDomain + '/file/' +company.serverStaticToken+'/'+ data.id + '/data',
      getUrlDownload: data.serverDomain + '/file/' +company.serverStaticToken+'/'+ data.id + '/download',
      getUrlFilename: data.serverDomain + '/file/' +company.serverStaticToken+'/'+ data.id +'/'+ data.name,
    }

  return {
    getUrl        : data.serverDomain + '/file/' + data.id,
    getUrlData    : data.serverDomain + '/file/' + data.id + '/data',
    getUrlDownload: data.serverDomain + '/file/' + data.id + '/download',
    getUrlFilename: data.serverDomain + '/file/' + data.id + '/' + data.name,
  }
}

export function fileChunkUpload({action, file, data = {}, onProgress, onSuccess, onError}) {

  console.log(file)

  async function uploadChunk({chunk, totalChunks, currentChunk, k=0, totalHash}) {
    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('totalChunks', totalChunks);
    formData.append('currentChunk', currentChunk);
    formData.append('totalHash', totalHash);
    data.name = file.name;
    data.mimetype = file.type;
    data.size = file.size;
    formData.append('data', JSON.stringify(data));

    const response = await fetch(action, {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      k = k + 1;
      if (k > 3) throw new Error('Chunk upload failed');
      await uploadChunk({chunk, totalChunks, currentChunk, k, totalHash})
    }
    onProgress({ percent: currentChunk*100/totalChunks })
    return response.json()
  }

  (async () => {
    const chunkSize = 1024 * 1024 * 10; // 10MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    let startByte = 0;

    let totalHash = CryptoJS.MD5('');
    for (let i = 1; i <= totalChunks; i++) {
      const endByte = Math.min(startByte + chunkSize, file.size);
      const chunk = file.slice(startByte, endByte);
      const chunkHash = CryptoJS.MD5(chunk).toString(); // Вычисляем хеш-сумму чанка
      totalHash.concat(CryptoJS.enc.Hex.parse(chunkHash)); // Объединяем хеш-суммы чанков в общий хеш
      startByte = endByte;
    }
    totalHash = CryptoJS.SHA256(totalHash);

    startByte = 0;
    for (let currentChunk = 1; currentChunk <= totalChunks; currentChunk++) {
      const endByte = Math.min(startByte + chunkSize, file.size);
      const chunk = file.slice(startByte, endByte);
      try {
        const d = await uploadChunk({chunk, totalChunks, currentChunk, totalHash});
        if (totalChunks === currentChunk) onSuccess(d)
      } catch (e) {
        onError(e)
      }

      startByte = endByte;
    }

  })()

}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload  = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const queryParam = (a) => {
  let s = [];
  let add = function (k, v) {
    v = typeof v === 'function' ? v() : v;
    v = v === null ? '' : v === undefined ? '' : v;
    s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
  };
  let buildParams = function (prefix, obj) {
    let i, len;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
            obj[i]
          );
        }
      } else if (Object.prototype.toString.call(obj) === '[object Object]') {
        for (let key of Object.keys(obj)) {
          buildParams(prefix + '[' + key + ']', obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (let key of Object.keys(obj)) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams('', a).join('&');
};

/**
 * обработкой ошибок и проверкой на json
 * @param url
 * @param options
 * @returns {Promise<unknown>}
 */
export async function apiQuery(url, options = {}){
  return new Promise(function (resolve, reject) {

    let domain = 'https://ta-patissiere.com/api';
    if (options.domain) domain = options.domain;

    if (options.method === 'GET') {
      url = url + '?' + queryParam(options.body);
      options.body = null;
    } else {
      if (options.body) options.body = JSON.stringify(options.body);
    }


    options.credentials = "include";

    let headers = {
      'Content-Type': 'application/json',
    }

    if (options.headers)
      options.headers = {headers, ...options.headers};
    else
      options.headers = headers;

    fetch(domain + url, options)
      .then(async res => {
        if (res.ok) return res;
        if (res.status === 400 || res.status === 401) {
          let body = await res.json();
          return Promise.reject({res, body})
        }
        let body = await res.text();
        return Promise.reject({res, body})
      })
      .then(res => res.json())
      .then(res => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function apiQueryError({error}){
  console.error(error)
  if(error?.res?.status === 401) userStore.setAuth(false);
  if(error?.res?.status === 404) message.error('Ошибка 404! Адрес не найден.');
  if(error?.body?.msg) message.error(error.body.msg);
}

const getUniqueBy = (arr, prop) => {
  const set = new Set();
  return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};

const Utils = {
  mobileCheck,
  fileAdapted,
  apiQuery,
  getUniqueBy,
  fileChunkUpload
}
export default Utils